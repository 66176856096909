import React, { useLayoutEffect, useState, useCallback, useMemo, memo } from "react";
import OurSolutionsData from "../OurSolutions/OurSolutionsData.js"
import Card from "../../Card.js";
import {Button, Container, Row, Col } from "react-bootstrap";
import "../OurSolutions/css/our-solutions.css";

function OurSolutions() {

    const [infoCard, setInfoCard] = useState("Bookmaker");
    const [buttonColor, setButtonColor] = useState("0");

    useLayoutEffect(() => {

    }, [])
    

    const toggleButtonColor = useCallback((event) => {
        setButtonColor(event.target.id);
    })

    const toggleInfoCard = useCallback((name) => {
        setInfoCard(name);
    })


    return (
        <div className="our-solutions">
            <Container className="our-solutions-container">
                <Row className="os-header-container">
                    <p className="os-header">OUR SOLUTIONS</p>
                    <p className="os-header-2">Products suited to your needs</p>
                    <p className='os-header-text'>
                            No matter what your company focuses on, we have a <a className="solution-text">solution for you</a>.
                        </p>
                </Row>
                <div className="os-products">
                    <Row className="os-button-group">
                        <Button className={buttonColor === "0" ? "os-button-1": "os-button-2"} id="0" onClick={(e) => {
                            toggleButtonColor(e);
                            toggleInfoCard("Bookmaker");
                        }}>
                            Bookmaker
                        </Button>
                        <Button className={buttonColor === "3" ? "os-button-1": "os-button-2"} key={3} id="3" onClick={(e) => {
                            toggleButtonColor(e);
                            toggleInfoCard("Daily Fantasy Sports");
                        }}>
                            Daily Fantasy Sports
                        </Button>
                        <Button className={buttonColor === "2" ? "os-button-1": "os-button-2"} key={2} id="2" onClick={(e) => {
                            toggleButtonColor(e);
                            toggleInfoCard("Media Fan Engagement");
                        }}>
                            Media Fan Engagement
                        </Button>
                        <Button className={buttonColor === "1" ? "os-button-1": "os-button-2"} key={1} id="1" onClick={(e) => {
                            toggleButtonColor(e);
                            toggleInfoCard("Tournament Organizer");
                        }}>
                            Tournament Organizer
                        </Button>
                    </Row>       
                    {<Card data={OurSolutionsData[infoCard]}/>}
                </div>
            </Container>
        </div>
    )
}

export default memo(OurSolutions);