import { Container, Col, Row } from "react-bootstrap";
import our_vision from "../../assets/images/about/our_vision.png"
import "../About/css/our-vision.css"


function OurVision() {
    return (
        <div className="our-vision">
            <Container className="our-vision-container">
                <Col className="our-vision-main" id="our-vision">
                    <Row className="about-header-container">
                        <p className="about-header-2">RIMBLE'S GOAL</p>
                        <p className="about-header-3">Our Vision</p>
                        <p className="about-header-text">We started Rimble back in 2019 in Berkeley, California after seeing a lack of accurate esports betting prediction models and analytics. When investigating the current state of the industry, we found that most esports analytics providers rely on inaccurate, non-automated models based on traditional sports. Instead, we decided to utilize unique machine learning models, low-latency computer vision, and game data pipelines to create Rimble—a real-time esports predictor and odds generator that outperforms the industry standard. Rimble’s mission is to revolutionize esports fan engagement by allowing businesses to build fan engagement and betting experiences on top of our analytics.
                        </p>
                    </Row>
                    <div className="our-vision-image-container">
                        <img className="our-vision-image" src={our_vision}></img>
                    </div>
                </Col>
            </Container>
        </div>
    )
}

export default OurVision;