import {Container, Col, Row} from 'react-bootstrap';
import "../HowItWorks/css/how-it-works.css";
import game from "../../../assets/images/how_it_works/games.png"
import computer_vision from "../../../assets/images/how_it_works/computer_vision.png"
import ml_models from "../../../assets/images/how_it_works/ml_models.png"
import raw_data from "../../../assets/images/how_it_works/raw_data_and_analytics.png"
import arrow from "../../../assets/images/how_it_works/arrow.png"
import "@fontsource/dm-sans"; 


function HowItWorks() {
    return (
        <div className="how-it-works" id="how-it-works">
             <Container className="how-it-works-container">
                <Row>
                    <p className="how-header">HOW IT WORKS</p>
                    <p className="how-header-2">Esports data reimagined</p>
                    <p className='how-header-text'>
                    Rimble's platform ingests vast amounts of historical data and live data generated with our proprietary computer vision technology. We then use custom built machine learning and automation to create our analytics and data feeds for micro events.
                    </p>
                </Row>
                <Col className="how-icons">
                    <div>
                        <img className="game-image" src={game} loading="lazy" decoding="async"></img>
                        <p className="game-text">Game</p>
                    </div>
                    <div className="arrow-icon">
                        <img className="arrow" src={arrow} loading="lazy" decoding="async"></img>
                    </div>
                    <div>
                        <img className="computer-vision" src={computer_vision} loading="lazy" decoding="async"></img>
                        <p className='computer-text'>Computer Vision</p>
                    </div>
                    <div className="arrow-icon">
                        <img className="arrow" src={arrow} loading="lazy" decoding="async"></img>
                    </div>
                    <div>
                        <img className="ml-model" src={ml_models} loading="lazy" decoding="async"></img>
                        <p className='ml-text'>ML Models</p>
                    </div>
                    <div className="arrow-icon">
                        <img className="arrow" src={arrow} loading="lazy" decoding="async"></img>
                    </div>
                    <div className="how-icon">
                        <img className="raw-data" src={raw_data} loading="lazy" decoding="async"></img>
                        <p className='raw-text'>Raw Data & Analytics</p>
                    </div>
                </Col>
            </Container>
        </div>
    );
}

export default HowItWorks;