import Layout from "../components/Layout.js";
import HomeHeader from "../components/Home/HomeHeader/HomeHeader.js";
import HowItWorks from "../components/Home/HowItWorks/HowItWorks.js";
import OurSolutions from "../components/Home/OurSolutions/OurSolutions.js";
import WhyRimble from "../components/Home/WhyRimble/WhyRimble.js";
import SupportedGames from "../components/Home/SupportGames/SupportGames.js";
import ContactUs from "../components/Home/ContactUs/ContactUs.js";
import MobileError from "../pages/MobileError/MobileError.js";


function LandingPage() {
    // var image = document.images[0]

    if(window.innerWidth < 992 || navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) ||navigator.userAgent.match(/iPod/i)) {
        return (
            // <div>
            // <h3>Please access the website through a desktop device...</h3>
            // </div>
            <MobileError/>
        );
    } else { 
        return (
            <Layout>
                <div>
                    <HomeHeader/>
                    <HowItWorks/>
                    <OurSolutions/>
                    <WhyRimble/>
                    <SupportedGames/>
                    <ContactUs/>
                </div>
            </Layout>
        );
    }
}

export default LandingPage;