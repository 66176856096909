import {Button, Container, Row, Col } from "react-bootstrap";
import "../WhyRimble/css/why-rimble.css"
import advanced_micro from "../../../assets/images/why_rimble/advanced_micro_analytics.png"
import unique_data from "../../../assets/images/why_rimble/unique_data_generation.png"
import real_time from "../../../assets/images/why_rimble/real_time_odds_support.png"


function WhyRimble() {
    return (
        <div className="why-rimble">
            <Container className="why-rimble-container">
                <Row className=" why-header-container">
                    <p className="why-header">WHY RIMBLE</p>
                    <p className="why-header-2">Industry leading support</p>
                </Row>
                <Col className="why-us-icons">
                    <div className="why-us-icon-container">
                        <img className="advanced-micro-analytics" src={advanced_micro} loading="lazy" decoding="async"></img>
                        <h1 className="why-icon-heading">Real Time Micro-Analytics</h1>
                        <p className="why-icon-text">Rimble offers a deeper level of fan engagement by focusing on insight and markets fans care about.</p>
                    </div>
                    <div className="why-us-icon-container">
                        <img className="unique-data-gen" src={unique_data} loading="lazy" decoding="async"></img>
                        <h1 className="why-icon-heading">Unique Data Generation</h1>
                        <p className="why-icon-text">Rimble has exclusive streamer feeds  to enhance your existing operations.</p>
                    </div>
                    <div className="why-us-icon-container">
                        <img className="real-time-odds" src={real_time} loading="lazy" decoding="async"></img>
                        <h1 className="why-icon-heading">Ease of Integration</h1>
                        <p className="why-icon-text">Our apis and white label experiences are designed to reduce development hassle and integration easier</p>
                    </div>
                </Col>
            </Container>
        </div>
    );
    }

export default WhyRimble;