import "../ContactUs/css/contact-us.css"
import React, {useState} from "react";
import {Container, Row, Col, Form, Button } from "react-bootstrap";
import emailjs from "emailjs-com";

function ContactUs() {

    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("")
 
    const sendEmail = (e) => {
        e.preventDefault();
            emailjs.sendForm('zoho', 'rimble_template', e.target, 'p8CsbB8WYGOt55XKu')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
            e.target.reset();
            setFirstName("");
            setLastName("");
            setEmail("");
            setMessage("");
    }
    return (
        <div className="contact-us" id="contact-us">
            <Container className="contact-us-container">
                <Row className="contact-header">
                    <p className="contact-header">CONTACT US</p>
                    <p className="contact-header-2">Interested in learning more?</p>
                </Row>
                <Row className="contact-us-info-container">
                    <Col className="text" xs={6}>
                        <p className="contact-header-text">
                            Want a quote or additional information? Fill out the form to contact our team!
                        </p>
                        <br/>
                        <a href="mailto:support@rimble.io" className="contact-header-text">
                            support@rimble.io
                        </a>
                    </Col>
                    <Col className="rb-contact-col" xs={6}>
                        <Row>
                        <Col className="rb-contact-modal" xs={11}>
                        <Form className="contact-modal-container" onSubmit={sendEmail}>
                            <Form.Group className="rb-contact-row" controlId="formBasicName">
                                <Form.Control type="text" name="firstname" placeholder="First Name*" className="rb-contact-field" onChange={e => setFirstName(e.target.value)}/>
                                <Form.Control type="text" name="lastname" placeholder="Last Name*" className="rb-contact-field" onChange={e => setLastName(e.target.value)}/>
                            </Form.Group>

                            <Form.Group  className="rb-contact-row" controlId="formBasicEmail">
                                <Form.Control type="email" name="email" placeholder="Email*" className="rb-contact-field" onChange={e => setEmail(e.target.value)}/>
                                <Form.Control type="text" name="companyname" placeholder="Company Name" className="rb-contact-field" />
                            </Form.Group>
                            <Form.Group className="rb-contact-row">
                                <Form.Control type="text" name="website" placeholder="Website" className="rb-contact-field-web"/>
                            </Form.Group>

                            <Form.Group className="rb-contact-row" controlId="formBasicText">
                                <Form.Control type="text" name="message" placeholder = "Message*" as="textarea" rows={8} className="rb-contact-field-message" style={{"resize": "none"}} onChange={e => setMessage(e.target.value)}/>
                            </Form.Group>
                            {firstname && lastname && email && message ?
                                <Button id="rimble_submit" type="submit" className="contact-us-rb-button contact-us-rb-submit">Submit</Button> :
                                <Button id="rimble_submit" type="submit" className="contact-us-rb-button contact-us-rb-submit" disabled="disabled">Submit</Button>
                            }
                        </Form>
                        </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ContactUs;