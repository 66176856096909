import "../Footer/css/footer.css"
import {Container, Col, Row} from "react-bootstrap";
import {HashLink} from "react-router-hash-link"

// import email from "../assets/images/footer/email.png"
// import linkedin from "../assets/images/footer/linkedin.png"


function Footer() {
    return (
        <footer className="footer-hold" id="footer">
            <div className="footer-container">
                <div className="footer-header-links">
                    <div>
                        <h1><a className="footer-rimble-header">Rimble</a></h1>
                    </div>
                    {/* <div className="footer-icon-links"> */}
                        {/* <img className="email-icon" src={email}></img>
                        <img className="linkedin" src={linkedin}></img> */}
                    {/* </div> */}
                </div>
                <div className="footer-links">
                    <div><strong className="footer-text-links">Product</strong></div>
                    {/* <div className="link-text"><a className="footer-link" href="#how-it-works">How it Works</a></div> */}
                    <div className="link-text"><HashLink className="footer-link" smooth to={"/#how-it-works"}>How it Works</HashLink></div>
                    <div className="link-text"><a className="footer-link" href="https://documenter.getpostman.com/view/16449503/Tzm8FvFw" target={"_blank"}>API Documentation</a></div>
                </div>
                <div className="footer-links">
                    <div><strong className="footer-text-links">About</strong></div>
                    <div className="link-text"><HashLink className="footer-link" smooth to={"/about/#our-vision"}>Our Mission</HashLink></div>
                    <div className="link-text"><HashLink className="footer-link" smooth to={"/about/#our-team"}>Our Team</HashLink></div>
                </div>
                <div className="footer-links">
                    <div><strong className="footer-text-links">Contact Us</strong></div>
                    <div className="link-text"><HashLink className="footer-link" smooth to={"/#how-it-works"}>Get Started</HashLink></div>
                    <div className="link-text"><a className="footer-link" href="mailto:support@rimble.io">
                        support@rimble.io
                    </a></div>
                </div>
                <div className="footer-links">
                    <div className="footer-text footer-link">Berkeley, CA</div>
                    <div className="footer-text footer-link">© Copyright 2022</div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;