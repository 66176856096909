import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import ReactGA from 'react-ga4';


const root = ReactDOM.createRoot(document.getElementById('root'));

// Shoudl be replaced with Google Analytics 4 property (`G-xxxxxxxxxx`)
// const TRACKING_ID = "UA-173231277-1"; 
// ReactGA.initialize(TRACKING_ID);

root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);



// const SendAnalytics = ()=> {
//   ReactGA.send({
//     hitType: "pageview",
//     page: window.location.pathname,
//   });
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
