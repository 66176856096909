import {Container, Row} from "react-bootstrap";
import TeamMember from "./team-member.js";
import shivam from "../../assets/images/about/shivam.png"
import sehaj from "../../assets/images/about/sehaj.png"
import vikram from "../../assets/images/about/vikram.png"
import "../About/css/our-team.css";

function OurTeam() {

    return (
        <div>
            
        </div>
    );
}

export default OurTeam;
