import "../GameCard/css/gamecard.css";
import {Col, Row} from "react-bootstrap"

function GameCard({data, id}) {
    for (let i = 0; i < data.length; i ++) {
        if (data[i].id === id) {
            return (
                <div className="game-card">
                    <Row className="game-card-text">
                        <p className="game-card-header">{data[i].header}</p>
                        <Col className="game-card-info-container">
                            <Col className="game-info-box">
                                <p className="game-card-info">{data[i].game_card_info[0]}</p>
                                <p className="game-card-info-2">{data[i].game_card_info_2[0]}</p>
                            </Col>
                            <Col className="game-info-box">
                                <p className="game-card-info">{data[i].game_card_info[1]}</p>
                                <p className="game-card-info-2">{data[i].game_card_info_2[1]}</p>
                            </Col>
                            <Col className="game-info-box">
                            <p className="game-card-info">{data[i].game_card_info[2]}</p>
                            <p className="game-card-info-2">{data[i].game_card_info_2[2]}</p>
                            </Col>
                        </Col>
                    </Row>
                </div>
            )
        }
    }
    // return (
    //     <div className="game-card">
    //         <Row className="game-card-text">
    //             <p className="game-card-header">League of Legends</p>
    //             <Col className="game-card-info-container">
    //                 <Col>
    //                     <p className="game-card-info">25+</p>
    //                     <p className="game-card-info-2">Leagues (LCS, LEC, LCK, LPL)</p>
    //                 </Col>
    //                 <Col>
    //                     <p className="game-card-info">6000+</p>
    //                     <p className="game-card-info-2">Matches a year</p>
    //                 </Col>
    //                 <Col>
    //                     <p className="game-card-info">20+</p>
    //                     <p className="game-card-info-2">Markets per game</p>
    //                 </Col>
    //             </Col>
    //             <p>Twitch Streamer analytics</p>
    //         </Row>
    //     </div>
    // )
}

export default GameCard;