import React, {useEffect} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Grid, _ } from 'gridjs-react';
import "gridjs/dist/theme/mermaid.css";
import "../components/Dashboard/css/dash-teams.css"
import Navbar from "../components/NavBar/NavBar.js";
import ProbabilityBar from "../components/Dashboard/ProbabilityBar.js";
import Layout from "../components/Layout";
import { BrowserRouter as Router, Link } from "react-router-dom";
import arrow from "../assets/images/dashboard/left-arrow.png"
import { type } from "@testing-library/user-event/dist/type";

class Teams extends React.Component {

    constructor(props) {

		super(props);

		this.state = {
            game_type: "",
            timeframe: "",
            league: "",
            matchid: "",
            team_1_name: "",
            team_2_name: "",
            matchup: [],
            team_1_stats: [],
            team_1_win_prob: 0,
            team_2_stats: [],
            team_2_win_prob: 0,
            odds: [],
            team_1_selected_stats: "default",
            team_2_selected_stats: "default",
            team_1_player_predictions: [],
            team_2_player_predictions: [],
            team_1_row_types:  new Set([]),
            team_2_row_types:  new Set([]),
            columns: new Set(["type"])
		};
	}

    getAPIKey(game) {
        return (game == "cricket" || game == "kabaddi") ? process.env.REACT_APP_RIMBLE3_API_KEY : process.env.REACT_APP_RIMBLE_API_KEY;
    }

    getLines(predictions) {
        this.setState({team_1_win_prob: predictions.teams[0].win_probability.toFixed(3), team_2_win_prob: predictions.teams[1].win_probability.toFixed(3)});
        // Parameter Sanitization
        const date_encoded = encodeURIComponent(predictions.date);
        const game_type_encoded = encodeURIComponent(this.props.params.game_type);
        const matchid_encoded = encodeURIComponent(this.props.params.matchid);

        fetch(
            "https://7cve45w77f.execute-api.us-east-1.amazonaws.com/prod/?date=" + date_encoded + "&game=" + game_type_encoded + "&gameid=" + matchid_encoded, {
                method: "GET",
                headers: {
                    "x-api-key": process.env.REACT_APP_RIMBLE_API_KEY
                }
            })
            .then((res) => res.json())
            .then((json) => {
                const body = JSON.parse(json.body);
                if ("no odds or projections for given gameid" in body) {
                    this.setState({odds: [{
                        'team_1_name': predictions.team_1_name,
                        'team_2_name': predictions.team_2_name,
                        'team_1_rimble_win_prob': predictions.teams[0].win_probability.toFixed(3),
                        'team_2_rimble_win_prob': predictions.teams[1].win_probability.toFixed(3),
                        'team_1_bookmaker_win_prob': 'n/a',
                        'team_2_bookmaker_win_prob': 'n/a',
                        'bookmaker': 'n/a'}]});
                    this.getPredictions(predictions, 0, this.getPlayers(predictions, 0), 'team_1_stats', []);
                    this.getPredictions(predictions, 1, this.getPlayers(predictions, 1), 'team_2_stats', []);
                } else {
                    var odds = []
                    for (const [key, value] of Object.entries(body.odds)) {
                        odds.push(...value);
                    }
                    if (odds.length == 0) {
                        this.setState({odds: [{
                            'team_1_name': predictions.team_1_name,
                            'team_2_name': predictions.team_2_name,
                            'team_1_rimble_win_prob': predictions.teams[0].win_probability.toFixed(3),
                            'team_2_rimble_win_prob': predictions.teams[1].win_probability.toFixed(3),
                            'team_1_bookmaker_win_prob': 'n/a',
                            'team_2_bookmaker_win_prob': 'n/a',
                            'bookmaker': 'n/a'}]});
                        this.getPredictions(predictions, 0, this.getPlayers(predictions, 0), 'team_1_stats', []);
                        this.getPredictions(predictions, 1, this.getPlayers(predictions, 1), 'team_2_stats', []);
                    } else {
                    for (let index in odds) {
                        if (odds[index].team_1_win_prob == "NA" || odds[index].team_2_win_prob == "NA") {
                            odds.splice(index, 1);
                        }
                        if (index < odds.length) {
                            odds[index]["team_1_name"] = this.props.params.team_1
                            odds[index]["team_2_name"] = this.props.params.team_2
                            odds[index]["team_1_bookmaker_win_prob"] = (odds[index].team_1_win_prob).toFixed(3)
                            odds[index]["team_2_bookmaker_win_prob"] = (odds[index].team_2_win_prob).toFixed(3)
                            odds[index]["team_1_rimble_win_prob"] = (this.state.team_1_win_prob)
                            odds[index]["team_2_rimble_win_prob"] = (this.state.team_2_win_prob)
                        }
                    }
                    this.setState({odds: odds});
                    // console.log("projected_stats", body);
                    if (body.teams[0].players.length != 0) {
                        this.getPredictions(predictions, 0, this.getPlayers(predictions, 0), 'team_1_stats', body.teams[0].players);
                        this.getPredictions(predictions, 1, this.getPlayers(predictions, 1), 'team_2_stats', body.teams[1].players);
                    }
                    else {
                        this.getPredictions(predictions, 0, this.getPlayers(predictions, 0), 'team_1_stats', []);
                        this.getPredictions(predictions, 1, this.getPlayers(predictions, 1), 'team_2_stats', []);
                    }
                    }
                }
            });
    }

    /**
     *
     * Updates the team_1_win_prob, team_2_win_prob, odds, team_1_stats, team_2 _stats and columns state variables
     *
     * @param {Object} predictions - dictionary of predictions for a match
     * @returns void
     */
    getNBALines(predictions) {

        this.setState({team_1_win_prob: predictions.teams[0].win_probability.toFixed(3), team_2_win_prob: predictions.teams[1].win_probability.toFixed(3)});

        var odds = []
        for (const [key, value] of Object.entries(predictions.odds)) {
            odds.push(...value);
        }
        // console.log("odds", odds)
        if (odds.length == 0) {
            this.setState({odds: [{
                'team_1_name': predictions.team_1_name,
                'team_2_name': predictions.team_2_name,
                'team_1_rimble_win_prob': predictions.teams[0].win_probability.toFixed(3),
                'team_2_rimble_win_prob': predictions.teams[1].win_probability.toFixed(3),
                'team_1_bookmaker_win_prob': 'n/a',
                'team_2_bookmaker_win_prob': 'n/a',
                'bookmaker': 'n/a'}]});
        } else {
            for (let index in odds) {
                if (odds[index].team_1_win_prob == "NA" || odds[index].team_2_win_prob == "NA") {
                    odds.splice(index, 1);
                }
                if (index < odds.length) {
                    odds[index]["team_1_name"] = this.props.params.team_1
                    odds[index]["team_2_name"] = this.props.params.team_2
                    odds[index]["team_1_bookmaker_win_prob"] = (odds[index].team_1_win_prob).toFixed(3)
                    odds[index]["team_2_bookmaker_win_prob"] = (odds[index].team_2_win_prob).toFixed(3)
                    odds[index]["team_1_rimble_win_prob"] = (this.state.team_1_win_prob)
                    odds[index]["team_2_rimble_win_prob"] = (this.state.team_2_win_prob)
                }
            }
            this.setState({odds: odds});
        }
        for (let team_num = 0; team_num < 2; team_num++) {
            const team = predictions.teams[team_num]
            let player_predictions = []
            let players = []
            let columnsSet = new Set()
            for (const player of team.players) {
                let playerPredictionsObj = {}
                playerPredictionsObj[player.name] = this.getNBAPlayerProjections(player)
                columnsSet = new Set([...columnsSet, ...Object.keys(playerPredictionsObj[player.name][0])])
                player_predictions.push(playerPredictionsObj)

                let playerObj = {}
                playerObj["name"] = player.name
                playerObj["username"] = player.name
                playerObj["id"] = "n/a"
                players.push(playerObj)
            }
            // console.log("columnsSet", columnsSet)
            this.setState({columns: columnsSet}, () => {
                if (team_num == 0) {
                    this.setState({'team_1_stats': this.formatTable(player_predictions, players, 0)})
                } else {
                    this.setState({'team_2_stats': this.formatTable(player_predictions, players, 1)})
                }
            })
        }
    }

    /**
     *
     * Returns
     *
     * @param {Object} playerData - object with keys name (string) and projections (object)
     * @returns {list} a list of dictionaries with projections from bookmakers for a player
     */
    getNBAPlayerProjections(playerData) {
        const { name, projections } = playerData;
        const output = { [name]: [] };
        const projectionTypes = Object.keys(projections);
        const projectionObj = {
          type: "prizepicks projected lines",
        };

        // Find all bookmakers for each projection type
        const bookmakers = projectionTypes.reduce((acc, curr) => {
          const currBookmakers = Object.keys(projections[curr]);
          return [...acc, ...currBookmakers];
        }, []);

        bookmakers.forEach((bookmaker) => {
          const bookmakerProjections = projectionTypes.reduce((acc, curr) => {
            if (bookmaker in projections[curr]) {
              acc[curr] = projections[curr][bookmaker][0].line.toString();
            } else {
              acc[curr] = "n/a";
            }
            return acc;
          }, {});

          const projection = output[name].find(
            (obj) => obj.type === `${bookmaker} projected lines`
          );

          if (projection) {
            Object.assign(projection, bookmakerProjections);
          } else {
            output[name].push({
              type: `${bookmaker} projected lines`,
              ...projectionObj,
              ...bookmakerProjections,
            });
          }
        });

        return Object.values(output)[0];
      }


    getPlayers(json, side) {
        if (this.props.params.game_type == 'cricket' || this.props.params.game_type == 'kabaddi') {
            return (
            json.teams[side].players.map((player, index) => ({
                name: player.name,
                username: player.name,
                id: player.id
            }))
        );
        } else {
            return (
            json.teams[side].players.map((player, index) => ({
                name: player.name,
                username: player.username,
                id: player.id
            }))
        );
        }
    }

    match_string(str1, str2) {
        return str1.toLowerCase().includes(str2.toLowerCase()) || str2.toLowerCase().includes(str1.toLowerCase());
    }

    getPredictions(predictions, team_num, players, state_var, projected_stats) {
        // console.log(team_num, players);
        // console.log(team_num, projected_stats);
        if (team_num == 0) {
            this.setState({team_1_win_prob: predictions.teams[team_num].win_probability.toFixed(3)})
        } else {
            this.setState({team_2_win_prob: predictions.teams[team_num].win_probability.toFixed(3)})
        }
        const team_players = predictions.teams[team_num].players;
        let prediction_stats = [];
        if (this.props.params.game_type == 'cricket' || this.props.params.game_type == 'kabaddi') {
            prediction_stats = players.map((player) => (
            {[player.name]: team_players.filter((item) => (item.name == player.name))[0]}
            ));
        } else {
            prediction_stats = players.map((player) => (
            {[player.username]: team_players.filter((item) => (item.username == player.username))[0]}
            ));
        }
        this.fetchMatchupStats(prediction_stats, players, state_var, team_num, projected_stats);
    }

    fetchPredictions(prediction_stats, matchup_stats, players, state_var, team_num, projected_stats) {

        const players_str = players.map((player) => (player.username)).toString().replace('`', '')

        // Parameter Sanitization
        const players_str_encoded = encodeURIComponent(players_str);
        const game_type_encoded = encodeURIComponent(this.props.params.game_type);

        fetch(
            "https://j3auzrc071.execute-api.us-east-1.amazonaws.com/prod/?players=" + players_str_encoded + "&game=" + game_type_encoded, {
                method: "GET",
                headers: {
                    "x-api-key": process.env.REACT_APP_RIMBLE_API_KEY
                },
            })
            .then((res) => res.json())
            .then((json) => {

                const body = JSON.parse(json.body);
                if (projected_stats.length != 0) {
                    var player_predictions = players.map((player, index) => ({
                        [player.username]: this.getGameSpecificStats(prediction_stats[index][player.username], body[player.username], matchup_stats, player, team_num, projected_stats[index])}));
                } else {
                    var player_predictions = players.map((player, index) => ({
                        [player.username]: this.getGameSpecificStats(prediction_stats[index][player.username], body[player.username], matchup_stats, player, team_num, [])}));
                }
                // console.log("player_predictions: ", player_predictions)
                // console.log("players: ", players)
                // console.log("team_num: ", team_num)
                if (team_num == 0) {
                    this.setState({ team_1_player_predictions: player_predictions })
                } else if (team_num == 1) {
                    this.setState({ team_2_player_predictions: player_predictions })
                }
                this.setState({[state_var]: this.formatTable(player_predictions, players, team_num)});
            });
    }

    fetchMatchupStats(prediction_stats, players, state_var, team_num, projected_stats) {
        // Parameter Sanitization
        const game_type_encoded = encodeURIComponent(this.props.params.game_type);
        const team_1_encoded = encodeURIComponent(this.props.params.team_1);
        const team_2_encoded = encodeURIComponent(this.props.params.team_2);

        fetch("https://jrkv0kwh80.execute-api.us-east-1.amazonaws.com/prod/?game=" + game_type_encoded + "&team1=" + team_1_encoded + "&team2=" + team_2_encoded, {
                    method: "GET",
                    headers: {
                        "x-api-key": process.env.REACT_APP_RIMBLE_API_KEY
                    }
                })
                .then((res) => res.json())
                .then((json) => {
                    const body = JSON.parse(json.body);
                    // console.log("matchup stats", body);
                    if ("no previous matches found" in body) {
                        this.fetchPredictions(prediction_stats, [], players, state_var, team_num, projected_stats)
                    } else {
                        this.fetchPredictions(prediction_stats, body, players, state_var, team_num, projected_stats)
                    }
                })
    }

    formatTable(stats, players, team_num) {

        // console.log(this.state.team_1_selected_stats)
        const selectedView = team_num === 0 ? this.state.team_1_selected_stats : this.state.team_2_selected_stats;
        const row_types = team_num === 0 ? this.state.team_1_row_types : this.state.team_2_row_types;
        if (this.props.params.game_type === "nba" || this.props.params.game_type === "soccer") {
            return (
                players.map((player, index) => {
                    let grid_data, grid_columns
                    grid_data = this.removeNARows(stats[index][player.username]).map(obj => {
                        const modifiedDict = {};

                        Object.keys(obj).forEach(key => {
                        const modifiedKey = key.replace(/\s+/g, "_").toLowerCase();
                        modifiedDict[modifiedKey] = obj[key];
                        });
                        return modifiedDict
                    })
                    // grid_columns = Array.from(this.state.columns).map(item => item.replace(/\s+/g, "_"))
                    grid_columns = Array.from(grid_data.reduce((acc, cur) => {
                        return [...acc, ...Object.keys(cur)];
                      }, [])).map(item => item.replace(/\s+/g, "_")).map(item => {
                        return {name: item, width: `${Math.max(item.length * 15, 110)}px`}
                    })
                // console.log("selectedView", selectedView)
                // console.log("grid_columns", grid_columns)
                // console.log("grid_data", grid_data)

                return {
                    name: player.name,
                    table: _(
                    <Grid
                        data={grid_data}
                        columns={grid_columns}
                        sort={true}
                    />)
                }})
            );
        } else {
            return (
                players.map((player, index) => {
                    let grid_data, grid_columns
                    if (selectedView == "default") {
                        grid_data = this.removeNARows(stats[index][player.username])

                        // Sort the columns in alphabetical order
                        let sorted_columns = [...this.state.columns].sort();
                        if (this.props.params.game_type != "cricket" || this.props.params.game_type != "kabaddi") {
                            grid_columns = new Set(['type', ...sorted_columns]);
                        } else {
                            grid_columns = this.state.columns;
                        }

                        // console.log("grid_columns", grid_columns, this.state.columns);
                        if (team_num == 0) {
                            this.setState(prevState => {
                                const newSet = new Set([...prevState.team_1_row_types, ...grid_data.map(row => row.type)]);
                                return { team_1_row_types: newSet };
                            })
                        } else if (team_num == 1) {
                            this.setState(prevState => {
                                const newSet = new Set([...prevState.team_2_row_types, ...grid_data.map(row => row.type)]);
                                return { team_2_row_types: newSet };
                            })
                        }
                    } else {
                        let data = stats[index][player.username].filter(row => row_types.has(row.type)).map(item => {
                            if (item.type.endsWith(' projected lines')) {
                                return { ...item, type: item.type.slice(0, -16) };
                            }
                            return item;
                        })
                        grid_data = [{  }]
                        // console.log("data", data)
                        data.forEach((obj) => {
                            grid_data[0][obj.type.replace(/\s+/g, "_")] = obj[selectedView]
                        })
                        grid_columns = data.map(obj => obj.type.replace(/\s+/g, "_"))
                    }
                // console.log("selectedView", selectedView)
                // console.log("grid_columns", grid_columns)
                // console.log("grid_data", grid_data)

                return {
                    name: player.name,
                    username: player.username,
                    id: player.id,
                    table: _(
                    <Grid
                        data={grid_data}
                        columns={grid_columns}
                        sort={true}
                    />) ,
                    button: _(
                        <Router>
                        <Link to={{pathname:'/#/players/' + this.props.params.game_type + '/' + this.props.params.team_1 + '/' + this.props.params.team_2 + '/' + team_num + '/' + this.props.params.timeframe + '/' + this.props.params.matchid + '/' + player.username}} onClick={() => {window.location.href='/#/players/' + this.props.params.game_type + '/' + this.props.params.team_1 + '/' + this.props.params.team_2 + '/' + team_num + '/' + this.props.params.timeframe + '/' + this.props.params.matchid + '/' + player.username}}>View Player Info</Link>
                        </Router>)
                }})
            );
        }
    }

    removeNARows(array) {
        var new_array = [] ;
        new_array = array.filter((obj)=> {
            for (const key in obj) {
                if (key !== 'type' && obj[key] !== 'n/a' && obj[key] !== '0.00') {
                    return true;
                }
            }
        });
        return new_array;
    }

    remove(array, item) {
        var new_array = [];
        if (array && (array.length != 1 || (array[0] && array[0] != []))) {
            new_array = array.filter((ar)=> ar != item);
        }
        return new_array;
    }

    getAverage(array) {
        array = this.remove(array, 'None');
        array = this.remove(array, null);
        if (array && array.length != 0) {
            return (array.reduce((a, b) => a + b) / array.length).toFixed(2);
        }
        else {
            return 'n/a';
        }
    }

    getMedian(array) {
        array = this.remove(array, 'None');
        if (array && array.length != 0 && array[0] != null) {
            const mid = Math.floor(array.length / 2);
            const nums = [...array].sort((a, b) => a - b);
            return (array.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2).toFixed(2);;
        } else {
            return 'n/a';
        }
    }

    getMatchupLine(win_stat, loss_stat, team_num) {
        if (win_stat == 'n/a' || loss_stat == 'n/a') {
            return 'n/a'
        }
        var win_prob;
        if (team_num == 0) {
            win_prob = this.state.team_1_win_prob;
        } else  {
            win_prob = this.state.team_2_win_prob;
        }
        return ((win_stat*win_prob)+(loss_stat*(1-win_prob))).toFixed(2)
    }

    getGameSpecificStats(prediction_stats, win_loss_stats, matchup_stats, player, team_num, projected_stats) {
        /* dynamic approach for all games, missing projections for COD and rocket league currently */

        // console.log("prediction_stats", prediction_stats);
        // console.log("projected stats", projected_stats);
        // console.log("matchup stats", matchup_stats);
        // console.log("win_loss_stats", win_loss_stats);

        // Alredy set for cricket as it has predfined stats columns
        var stats_columns = this.state.columns;

        var last_n = 5;
        if (this.props.params.game_type == "cricket" || this.props.params.game_type == "kabaddi") {
            last_n = 10;
        } else {
            for (let index in prediction_stats.projections) {
                if (index.includes("_prediction")) {
                    // console.log(`test:  + ${index.substring(0, index.indexOf("_prediction"))}`)
                    stats_columns.add(index.substring(0, index.indexOf("_prediction")))
                } else if (index.includes("_last5")) {
                    stats_columns.add(index.substring(0, index.indexOf("_last5")))
                }
            }
            this.setState({columns: stats_columns});
        }


        const prediction_dict = {type: "prediction"};
        const last_n_dict = {type: "last " + last_n};
        if (prediction_stats.projections) {
            last_n_dict.participation = prediction_stats.projections["participation_last" + last_n]
        }
        const win_dict = {type: "wins"}
        const loss_dict = {type: "losses"}
        const matchup_perf_dict = {type: "matchup performance"}
        const matchup_line_dict = {type: "matchup line"}
        const result_dict = {type: "results"}
        const line_rating_dict = {type: "line rating"}
        var projection_dict_list = new Set();

        // dynamic approach to get types (lines name) for projection stats from different betting website
        if (projected_stats && projected_stats.length !== 0) {
            for (let lines of Object.values(projected_stats.projections)) {
                for (let line_name in lines) {
                    if (line_name.includes("_projections")) {
                        projection_dict_list.add(line_name.substring(0, line_name.indexOf("_projections")) + " projected lines");
                    }
                }
            }
        }
        projection_dict_list = Array.from(projection_dict_list).map(line_name => { return {type: line_name}});
        // console.log("projection_dict", projection_dict_list)

        var closing_projections_list = projection_dict_list.map(line => { return {type: line.type}});

        // console.log("stats:", stats_columns);
        for (const column_name of stats_columns) { // instead of hardcoding stats, iterate over keys of json data
            //
            if (column_name !== "type" && column_name !== "participation") {
                const prediction_var = column_name + "_prediction";
                try {
                    prediction_dict[column_name] = this.getMedian([prediction_stats.projections[prediction_var]]);
                } catch (error) {
                    prediction_dict[column_name] = "n/a"
                    // console.log("predictions", column_name);
                }
                const last_n_var = column_name + "_last" + last_n;
                try {
                    last_n_dict[column_name] = this.getAverage(prediction_stats.projections[last_n_var].slice(-last_n));
                } catch (error) {
                    last_n_dict[column_name] = "n/a"
                    // console.log("last n", column_name);
                }
                const line_rating_var = column_name + "_line_rating";
                try {
                    line_rating_dict[column_name] = this.getMedian([prediction_stats.projections[line_rating_var]]);
                } catch (error) {
                    line_rating_dict[column_name] = "n/a"
                    // console.log("last n", column_name);
                }
                // projection_stats dynamic approach
                for (let i = 0; i < projection_dict_list.length; i++) {
                    try {
                        const line_name = projection_dict_list[i].type.slice(0, -16) + "_projections";
                        const column_name_upper_1st = column_name.charAt(0).toUpperCase() + column_name.slice(1);
                        const projection_data = projected_stats.projections[column_name_upper_1st][line_name].map((data) => (data.line));
                        if (projection_data.length > 1) {
                            let closing_line = closing_projections_list.find(line => line.type == projection_dict_list[i].type);
                            closing_line[column_name] = this.getAverage([projection_data[projection_data.length - 1]]);
                        }
                        projection_dict_list[i][column_name] = this.getAverage([projection_data[0]])
                    } catch (error) {
                        projection_dict_list[i][column_name] = "n/a"
                        // console.log("missing", column_name)
                    }
                }

                if (this.props.params.timeframe === 'upcoming-matches') {
                    try {
                        win_dict[column_name] = this.getMedian(win_loss_stats.stats.wl_data.wins[column_name].slice(-8));
                    } catch (error) {
                        win_dict[column_name] = "n/a"
                        // console.log("wins", column_name);
                    }
                    try {
                        loss_dict[column_name] = this.getMedian(win_loss_stats.stats.wl_data.losses[column_name].slice(-8));
                    } catch (error) {
                        loss_dict[column_name] = "n/a"
                        // console.log("losses", column_name);
                    }
                    try {
                        if (matchup_stats[player.username][0][column_name]) {
                            matchup_perf_dict[column_name] = this.getAverage(matchup_stats[player.username].map((game) => (game[column_name])));
                        } else {
                            matchup_perf_dict[column_name] = this.getAverage(matchup_stats[player.username].map((game) => (game[column_name.toUpperCase()])));
                        }
                    } catch (error) {matchup_perf_dict[column_name] = "n/a";
                        // console.log("matchup performace", column_name);
                    }
                    try {
                        if (win_loss_stats.stats.wl_data){
                            matchup_line_dict[column_name] = this.getMatchupLine(this.getMedian(win_loss_stats.stats.wl_data.wins[column_name].slice(-8)), this.getMedian(win_loss_stats.stats.wl_data.losses[column_name].slice(-8)), team_num)
                        } else {
                            matchup_line_dict[column_name] = this.getMatchupLine(this.getMedian(win_loss_stats.stats.wins[column_name].slice(-8)), this.getMedian(win_loss_stats.stats.losses[column_name].slice(-8)), team_num)
                        }
                    } catch (error) {
                        matchup_line_dict[column_name] = "n/a";
                        // console.log("matchup line", column_name);
                    }
                } else {
                    try {
                        // if (matchup_stats[player.username][0][column_name])
                        result_dict[column_name] = this.getAverage(prediction_stats.results.map((game) => (game[column_name])))
                        // result_dict[column_name] = this.getAverage(prediction_stats.results.map((game) => (game[column_name.toUpperCase()])))
                    } catch (error) {
                        result_dict[column_name] = "n/a";
                        // console.log("results", column_name);
                    }
                }
            }
        }


        // Add opening and closing projection line to projection_dict_list if applicable
        for (let closing_line of closing_projections_list) {
            if (Object.keys(closing_line).length > 1) {
                let opening_line_index = projection_dict_list.findIndex(line => line.type == closing_line.type);
                let opening_line = projection_dict_list[opening_line_index];
                closing_line = {...opening_line, ...closing_line};
                opening_line.type = "opening " + opening_line.type;
                closing_line.type = "closing " + closing_line.type;
                projection_dict_list.splice(opening_line_index + 1, 0, closing_line);
            }
        }

        // this.setState({columns: stats_columns});

        if (this.props.params.timeframe === 'upcoming-matches') {
            return ([prediction_dict, line_rating_dict, last_n_dict, win_dict, loss_dict, matchup_perf_dict, matchup_line_dict, ...projection_dict_list]);
        } else {
            return ([prediction_dict, line_rating_dict, last_n_dict, result_dict, ...projection_dict_list]);
        }
    }

    componentDidMount() {
        // Handling for NBA gametype for calling the nba endpoint and setting state appropriately
        if (this.props.params.game_type == "nba" || this.props.params.game_type == "soccer") {
            // Parameter Sanitization
            const game_type_encoded = encodeURIComponent(this.props.params.game_type);

            fetch(
                "https://kvru1gtful.execute-api.us-east-1.amazonaws.com/prod/?game=" + game_type_encoded,
                {
                    method: "GET",
                    headers: {
                        "x-api-key": process.env.REACT_APP_RIMBLE_API_KEY
                    }
            })
            .then((res) => res.json())
            .then((res) => res.Items)
            .then((res) => res.filter(matchup => matchup.gameid === this.props.params.matchid))
            .then((json) => {
                this.getNBALines(json[0]);
                this.setState({
                    matchup: json,
                    game_type: this.props.params.game_type,
                    timeframe: this.props.params.timeframe,
                    matchid: this.props.params.matchid,
                    league: this.props.params.game_type.toUpperCase,
                    team_1_name: this.props.params.team_1,
                    team_2_name: this.props.params.team_2
                });
            });
        } else {
            // Parameter Sanitization
            const game_type_encoded = encodeURIComponent(this.props.params.game_type);
            const timeframe_encoded = encodeURIComponent(this.props.params.timeframe);
            const matchid_encoded = encodeURIComponent(this.props.params.matchid);

            fetch(
                "https://rimbleanalytics.com/predictions/" + game_type_encoded + "/" + timeframe_encoded + "/?matchid=" + matchid_encoded,
                {
                    method: "GET",
                    headers: {
                        "x-api-key": this.getAPIKey(this.props.params.game_type),
                    }
            })
            .then((res) => res.json())
            .then((json) => {
                this.getLines(json[0]);
                //this.getPredictions(json[0], 0, this.getPlayers(json[0], 0), 'team_1_stats');
                //this.getPredictions(json[0], 1, this.getPlayers(json[0], 1), 'team_2_stats');
                const updatedMatchup = json.map(match => {
                    // Safely check if teams array and its elements exist before trying to access win_result
                    const team1WinResult = match.teams && match.teams[0] ? match.teams[0].win_result : undefined;
                    const team2WinResult = match.teams && match.teams[1] ? match.teams[1].win_result : undefined;

                    // Return a new match object with the new keys added
                    return {
                        ...match, // Spread the existing match properties
                        team_1_win_result: team1WinResult,
                        team_2_win_result: team2WinResult,
                    };
                });
                this.setState({
                    matchup: updatedMatchup,
                    game_type: this.props.params.game_type,
                    timeframe: this.props.params.timeframe,
                    matchid: this.props.params.matchid,
                    league: json[0].league,
                    team_1_name: this.props.params.team_1,
                    team_2_name: this.props.params.team_2
                });
                // Lag in updating columns for cricket, so updating early
                if (this.props.params.game_type == "cricket") {
                    this.setState({columns: new Set(["type", "runs", "strikerate", "wickets", "economy", "participation"])});
                }
                if (this.props.params.game_type == "kabaddi") {
                    this.setState({columns: new Set(["type", "points_total", "participation"])});
                }

            });
        }
    }

    goBack() {
        this.props.history.back();
    }

    handleOptionChange = (option, team_num) => {
        // console.log("option: ", option, "team_num: ", team_num)
        if (team_num == 0) {
            const players = this.state.team_1_stats.map(({ table, button, ...rest }) => rest)
            this.setState({ team_1_selected_stats: option }, () => {
                this.setState({'team_1_stats': this.formatTable(this.state.team_1_player_predictions, players, 0)})
              });

        } else if (team_num == 1) {
            const players = this.state.team_2_stats.map(({ table, button, ...rest }) => rest)
            this.setState({ team_2_selected_stats: option }, () => {
                this.setState({'team_2_stats': this.formatTable(this.state.team_2_player_predictions, players, 1)})
              });
        }
    };

    getPlayerColumns() {
        if (this.state.game_type == "cricket" || this.state.game_type == "kabaddi") {
            return ["name", "button", "table"];
        } else {
            return ["name", "username", "button", "table"];
        }
    }

    render() {
        if (!this.state.game_type) return (
			<div>
            <Layout>
                <h1> Please wait a moment....
				</h1>
            </Layout>
			</div>
        );
        let viewOptions
        // this.state.game_type == 'rocketleague' || this.state.game_type == 'cod' || this.state.game_type == 'nba' || this.state.game_type == "soccer"
        // take options from dynamically created this.state.columns except cricket
        let sortedColumns;
        if (this.props.params.game_type != "cricket" || this.props.params.game_type != "kabaddi") {
            sortedColumns = [...this.state.columns].sort();
        } else {
            sortedColumns = Array.from(this.state.columns);
        }
        viewOptions = [
            { value: "default"},
            ...sortedColumns
            .filter(item => item !== "type")
            .map(item => {
                return {value: item}
            })
        ];
        // } else {
        //     // take options from hardcoded game_type_map
        //     viewOptions = [
        //         { value: "default"},
        //         ...Array.from(this.game_type_map[this.props.params.game_type]["cols"])
        //         .filter(item => item !== "type")
        //         .map(item => {
        //             return {value: item}
        //         })
        //     ];
        // }

        if (this.state.game_type == "nba" || this.state.game_type == "soccer") return (
            <div>
            <Layout>
                <Grid
                    data={this.state.matchup}
                    columns={[
                    'gameid',
                    'date',
                    'team_1_name',
                    'team_2_name'
                    ]}
                    sort={true}
                    search={true}
                    />
                    <h3>Team 1 Table</h3>
                    <Grid
                    data={this.state.team_1_stats}
                    columns={[
                    'name',
                    'table'
                    ]}
                    sort={true}
                    />
                    <h3>Team 2 Table</h3>
                    <Grid
                    data={this.state.team_2_stats}
                    columns={[
                    'name',
                    'table'
                    ]}
                    sort={true}
                    />
                <h3>Odds Table</h3>


                    <Grid
                    data={this.state.odds}
                    columns={[
                    'team_1_name',
                    'team_2_name',
                    'team_1_rimble_win_prob',
                    'team_2_rimble_win_prob',
                    'team_1_bookmaker_win_prob',
                    'team_2_bookmaker_win_prob',
                    'bookmaker'
                    ]}
                    sort={true}
                    search={true}
                    />
            </Layout>
            </div>
        );

        return (
            <Layout>
                <p>If any data is missing, please try refreshing the page...</p>
                <p>Currently selected game: {this.state.game_type}</p>
                <p>Currently selected timeframe: {this.state.timeframe}</p>

                    <Grid
                    data={this.state.matchup}
                    columns={[
                    'matchid',
                    'date',
                    'time',
                    'league',
                    'team_1_name',
                    'team_2_name',
                    'team_1_win_result',
                    'team_2_win_result'
                    ]}
                    sort={true}
                    search={true}
                    />
                <h3>Team 1 Table</h3>
                    <select value={this.state.team_1_selected_stats} onChange={(event) => this.handleOptionChange(event.target.value, 0)}>
                    {viewOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                        {option.value}
                        </option>
                    ))}
                    </select>

                    <Grid
                    data={this.state.team_1_stats}
                    columns={this.getPlayerColumns()}
                    sort={true}
                    />
                <h3>Team 2 Table</h3>
                    <select value={this.state.team_2_selected_stats} onChange={(event) => this.handleOptionChange(event.target.value, 1)}>
                        {viewOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                            {option.value}
                            </option>
                        ))}
                    </select>
                    <Grid
                    data={this.state.team_2_stats}
                    columns={this.getPlayerColumns()}
                    sort={true}
                    />
                <h3>Odds Table</h3>


                    <Grid
                    data={this.state.odds}
                    columns={[
                    'team_1_name',
                    'team_2_name',
                    'team_1_rimble_win_prob',
                    'team_2_rimble_win_prob',
                    'team_1_bookmaker_win_prob',
                    'team_2_bookmaker_win_prob',
                    'bookmaker'
                    ]}
                    sort={true}
                    search={true}
                    />
                </Layout>
        );
    }
}

export default (props) => (
    <Teams
        {...props}
        params={useParams()}
    />
);
