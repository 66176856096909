import "../NavBar/css/Navbar.css";
import {Container, Navbar, Nav} from "react-bootstrap";
import {HashLink} from "react-router-hash-link"


function NavBar() {
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener, noreferrer');
    };
    return (
        <div>
        <Navbar bg="white" className="rb-navbar">
            <Container className="rb-navbar-container">
                <Navbar.Brand href="/" className="rb-nav-brand">
                    Rimble
                </Navbar.Brand>
                <Navbar.Collapse>
                    <Nav>
                        <Nav.Link className="rb-nav-link" onClick={() => openInNewTab("https://documenter.getpostman.com/view/16449503/Tzm8FvFw")}>API Documentation</Nav.Link>
                        <Nav.Link className="rb-nav-link" href="/#/dashboard">Dashboard</Nav.Link>
                        {/* <Nav.Link className="rb-nav-link" href="/#/peel-blue-dashboard">Peel Blue</Nav.Link> */}
                        <Nav.Link className="rb-nav-link" href="/#/about">About</Nav.Link>
                        {/* <Nav.Link className="rb-nav-link" href="/#/"
                        >Contact Us</Nav.Link> */}
                        <HashLink className="rb-nav-link" smooth to={"/#contact-us"}>Contact Us</HashLink>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        </div>
    )
}

export default NavBar;