import React from "react";
import {CircularProgressbar} from "react-circular-progressbar";
import "../Dashboard/css/probability-bar.css"

const ProbabilityBar = (props) => {

    const {score} = props;
    if (props.id === "1") {
    return (
            <div className="circle-win-probability">
                <CircularProgressbar
                    value={score * 100}
                    text={score}
                    circleRatio={1}
                    styles={{
                        
                        trail: {
                            strokeLinecap: "round",
                            transform: "rotate(-90deg)",
                            transformOrigin: "center center",
                            stroke: "#D9D9D9"
                        },
                        path: {
                            strokeLinecap: "round",
                            transform: "rotate(360deg)",
                            transformOrigin: "center center",
                            stroke: "rgba(65, 111, 223, 1)"
                        },
                        
                    }}
                    strokeWidth={14}
                    counterClockwise={true}
                />
                </div>
                )} else if (props.id === "2") {
                    return (
                        <div>
                        <CircularProgressbar
                            value={score * 100}
                            text={score}
                            circleRatio={1}
                            styles={{
                                
                                trail: {
                                    strokeLinecap: "round",
                                    transform: "rotate(-90deg)",
                                    transformOrigin: "center center",
                                    stroke: "#D9D9D9"
                                },
                                path: {
                                    strokeLinecap: "round",
                                    transform: "rotate(360deg)",
                                    transformOrigin: "center center",
                                    stroke: "rgba(46, 73, 147, 1)"
                                },
                                
                            }}
                            strokeWidth={14}
                            counterClockwise={true}
                        />
                    </div>

            )}
}

export default ProbabilityBar;

