import React from "react";
import { useParams } from "react-router-dom";
import { Grid, _ } from 'gridjs-react';
import "gridjs/dist/theme/mermaid.css";
import Navbar from "../components/NavBar/NavBar.js";
import Layout from "../components/Layout.js";

class Players extends React.Component {

    constructor(props) {

		super(props);

		this.state = {
            player_stats: [],
            team_1_win_prob: 0,
            team_2_win_prob: 0,
            projections: [],
            projections_cols: ["type", "line", "time_scraped"],
            columns: new Set(["type"])
		};
	}

    getPlayers(json, side, player) {
        if (this.props.params.game_type == 'cricket' || this.props.params.game_type == 'kabaddi') {
            var players_list = json.teams[side].players.map((player, index) => ({
                name: player.name,
                username: player.name,
                id: player.id,
                image: player.image,
            }))
        } else {
            var players_list = json.teams[side].players.map((player, index) => ({
                name: player.name,
                username: player.username,
                id: player.id,
                image: player.image,
            }))
        }
        return players_list.filter((item) => (item.username == player));

        // return [json[0].teams[side].players.filter((item) => (item.username == player))[0]]
    }

    getProjections(date) {

        // Parameter Sanitization
        const date_encoded = encodeURIComponent(date);
        const game_type_encoded = encodeURIComponent(this.props.params.game_type);
        const matchid_encoded = encodeURIComponent(this.props.params.matchid);

        fetch(
            "https://7cve45w77f.execute-api.us-east-1.amazonaws.com/prod/?date=" + date_encoded + "&game=" + game_type_encoded + "&gameid=" + matchid_encoded, {
                method: "GET",
                headers: {
                    "x-api-key": process.env.REACT_APP_RIMBLE_API_KEY
                }
            })
            .then((res) => res.json())
            .then((json) => {
                const body = JSON.parse(json.body);
                // console.log("body", body);
                if ("no odds or projections for given gameid" in body || body.teams[0].players.length == 0) {
                    this.setState({projections: []});
                } else {
                    if (this.props.params.game_type == "cricket" || this.props.params.game_type == "kabaddi") {
                        var player = body.teams[this.props.params.team_num].players.filter((item) => (item.name == this.props.params.player));
                    } else {
                        var player = body.teams[this.props.params.team_num].players.filter((item) => (item.username == this.props.params.player));
                    }
                    if (player) {
                        this.getProjectionSpecificStats(player[0].projections);
                    }
                }
            });
    }

    getPredictions(predictions, team_num, players, state_var) {
        if (team_num == 0) {
            this.setState({team_1_win_prob: predictions.teams[team_num].win_probability.toFixed(3)})
        } else {
            this.setState({team_2_win_prob: predictions.teams[team_num].win_probability.toFixed(3)})
        }

        const team_players = predictions.teams[team_num].players;
        if (this.props.params.game_type == "cricket" || this.props.params.game_type == "kabaddi") {
            var prediction_stats = players.map((player) => (
            {[player.name]: team_players.filter((item) => (item.name == player.name))[0]}
            ));
        } else {
            var prediction_stats = players.map((player) => (
            {[player.username]: team_players.filter((item) => (item.username == player.username))[0]}
            ));
        }

        if (this.props.params.timeframe == "upcoming-matches") {
            this.fetchMatchupStats(prediction_stats, players, state_var, team_num);
        } else {
            this.fetchPredictions(prediction_stats, [], players, state_var, team_num);
        }
    }

    fetchMatchupStats(prediction_stats, players, state_var, team_num) {
        // Parameter Sanitization
        const game_type_encoded = encodeURIComponent(this.props.params.game_type);
        const team_1_encoded = encodeURIComponent(this.props.params.team_1);
        const team_2_encoded = encodeURIComponent(this.props.params.team_2);

        fetch("https://jrkv0kwh80.execute-api.us-east-1.amazonaws.com/prod/?game=" +game_type_encoded + "&team1=" + team_1_encoded + "&team2=" + team_2_encoded, {
                method: "GET",
                headers: {
                    "x-api-key": process.env.REACT_APP_RIMBLE_API_KEY
                }
            })
            .then((res) => res.json())
            .then((json) => {
                const body = JSON.parse(json.body);
                if ("no previous matches found" in body) {
                    this.fetchPredictions(prediction_stats, [], players, state_var, team_num)
                } else {
                    this.fetchPredictions(prediction_stats, body, players, state_var, team_num)
                }
            })
    }

    fetchPredictions(prediction_stats, matchup_stats, players, state_var, team_num) {
        const players_str = players.map((player) => (player.username)).toString().replace('`', '')

        // Parameter Sanitization
        const players_str_encoded = encodeURIComponent(players_str);
        const game_type_encoded = encodeURIComponent(this.props.params.game_type);

        fetch(
            "https://j3auzrc071.execute-api.us-east-1.amazonaws.com/prod/?players=" + players_str_encoded + "&game=" + game_type_encoded, {
                method: "GET",
                headers: {
                    "x-api-key": process.env.REACT_APP_RIMBLE_API_KEY
                }
            })
            .then((res) => res.json())
            .then((json) => {
                const body = JSON.parse(json.body);
                if (this.props.params.game_type == "cricket" || this.props.params.game_type == "kabaddi") {
                    var player_predictions = players.map((player, index) => ({
                    [player.name]: this.removeNARows(this.getGameSpecificStats(prediction_stats[index][player.name], body[player.name], matchup_stats, player, team_num))}));
                } else {
                    var player_predictions = players.map((player, index) => ({
                    [player.username]: this.removeNARows(this.getGameSpecificStats(prediction_stats[index][player.username], body[player.username], matchup_stats, player, team_num))}));
                }
                this.setState({[state_var]: this.formatStats(player_predictions, players)});
            });
    }

    formatStats(stats, players) {

        // Sort the columns in alphabetical order except cricket
        let sorted_columns = [...this.state.columns].sort();
        if (this.props.params.game_type != "cricket" || this.props.params.game_type != "kabaddi") {
            sorted_columns = new Set(['type', ...sorted_columns]);
        } else {
            sorted_columns = this.state.columns;
        }

        return (
            players.map((player, index) => (
            {
                name: player.name,
                username: player.username,
                image: _(<img src={player.image} alt="Image not available" width="128" height="128"></img>),
                table: _(<Grid
                    data={stats[index][player.username]}
                    columns = {sorted_columns}
                    sort={true}
                    search={true}
                />)
            }))
        );
    }

    removeNARows(array) {
        var new_array = [] ;
        var new_array = array.filter((obj)=> {
            for (const key in obj) {
                if (key !== 'type' && obj[key] !== 'n/a') {
                    return true;
                }
            }
        });
        return new_array;
    }

    getAverage(array) {
        array = this.remove(array, 'None');
        if (array && array.length != 0) {
            return (array.reduce((a, b) => a + b) / array.length).toFixed(2);
        }
        else {
            return 'n/a';
        }
    }

    getMedian(array) {
        array = this.remove(array, 'None');
        if (array && array.length != 0 && array[0] != null) {
            const mid = Math.floor(array.length / 2);
            const nums = [...array].sort((a, b) => a - b);
            return (array.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2).toFixed(2);;
        } else {
            return 'n/a';
        }
    }

    remove(array, item) {
        var new_array = [];
        if (array && (array.length != 1 || (array[0] && array[0] != []))) {
            var new_array = array.filter((ar)=> ar != item);
        }
        return new_array;
    }

    getMatchupLine(win_stat, loss_stat, team_num) {
        if (win_stat == 'n/a' || loss_stat == 'n/a') {
            return 'n/a'
        }
        if (team_num == 0) {
            var win_prob = this.state.team_1_win_prob;
        } else  {
            var win_prob = this.state.team_2_win_prob;
        }
        return ((win_stat*win_prob)+(loss_stat*(1-win_prob))).toFixed(2)
    }

    getProjectionSpecificStats(raw_projections) {
        // Dyanmic approach to get projections from providers

        var projection_dict_list = []

        console.log("raw projections", raw_projections);
        for (let stat in raw_projections) {
            for (let providers of Object.values(raw_projections[stat])) {
                providers.map((line) => {
                    projection_dict_list.push({"type": stat, "line": line.line, "time_scraped": line.time_scraped});
                })

            }
        }
        this.setState({"projections": projection_dict_list});
    }

    getGameSpecificStats(prediction_stats, win_loss_stats, matchup_stats, player, team_num) {

        /* dynamic approach for all games, missing projections for COD and rocket league currently */

        // console.log("prediction_stats", prediction_stats);
        // console.log("projected stats", projected_stats);
        // console.log("matchup stats", matchup_stats);
        // console.log("win_loss_stats", win_loss_stats);

        // Alredy set for cricket as it has predfined stats columns
        var stats_columns = this.state.columns;

        var last_n = 5;
        if (this.props.params.game_type == "cricket" || this.props.params.game_type == "kabaddi") {
            last_n = 10;
        } else {
            for (let index in prediction_stats.projections) {
                if (index.includes("_prediction")) {
                    // console.log(`test:  + ${index.substring(0, index.indexOf("_prediction"))}`)
                    stats_columns.add(index.substring(0, index.indexOf("_prediction")))
                } else if (index.includes("_last5")) {
                    stats_columns.add(index.substring(0, index.indexOf("_last5")))
                }
            }
            this.setState({columns: stats_columns});
        }

        const prediction_dict = {type: "prediction"};
        const last_n_dict = {type: "last " + last_n};
        if (prediction_stats.projections) {
            last_n_dict.participation = prediction_stats.projections["participation_last" + last_n]
        }
        const win_dict = {type: "wins"}
        const loss_dict = {type: "losses"}
        const matchup_perf_dict = {type: "matchup performance"}
        const matchup_line_dict = {type: "matchup line"}
        const line_rating_dict = {type: "line rating"}
        const result_dict = {type: "results"}

        // console.log("stats:", stats_columns);
        for (const column_name of stats_columns) { // instead of hardcoding stats, iterate over keys of json data
            //
            if (column_name !== "type" && column_name !== "participation") {
                const prediction_var = column_name + "_prediction";
                try {
                    prediction_dict[column_name] = this.getMedian([prediction_stats.projections[prediction_var]]);
                } catch (error) {
                    prediction_dict[column_name] = "n/a"
                    // console.log("predictions", column_name);
                }
                const last_n_var = column_name + "_last" + last_n;
                try {
                    last_n_dict[column_name] = this.getAverage(prediction_stats.projections[last_n_var].slice(-last_n));
                } catch (error) {
                    last_n_dict[column_name] = "n/a"
                    // console.log("last n", column_name);
                }
                const line_rating_var = column_name + "_line_rating";
                try {
                    line_rating_dict[column_name] = this.getMedian([prediction_stats.projections[line_rating_var]])
                } catch (error) {
                    line_rating_dict[column_name] = "n/a"
                }

                if (this.props.params.timeframe === 'upcoming-matches') {
                    try {
                        win_dict[column_name] = this.getMedian(win_loss_stats.stats.wl_data.wins[column_name].slice(-8));
                    } catch (error) {
                        win_dict[column_name] = "n/a"
                        // console.log("wins", column_name);
                    }
                    try {
                        loss_dict[column_name] = this.getMedian(win_loss_stats.stats.wl_data.losses[column_name].slice(-8));
                    } catch (error) {
                        loss_dict[column_name] = "n/a"
                        // console.log("losses", column_name);
                    }
                    try {
                        if (matchup_stats[player.username][0][column_name]) {
                            matchup_perf_dict[column_name] = this.getAverage(matchup_stats[player.username].map((game) => (game[column_name])));
                        } else {
                            matchup_perf_dict[column_name] = this.getAverage(matchup_stats[player.username].map((game) => (game[column_name.toUpperCase()])));
                        }
                    } catch (error) {matchup_perf_dict[column_name] = "n/a";
                        // console.log("matchup performace", column_name);
                    }
                    try {
                        if (win_loss_stats.stats.wl_data){
                            matchup_line_dict[column_name] = this.getMatchupLine(this.getMedian(win_loss_stats.stats.wl_data.wins[column_name].slice(-8)), this.getMedian(win_loss_stats.stats.wl_data.losses[column_name].slice(-8)), team_num)
                        } else {
                            matchup_line_dict[column_name] = this.getMatchupLine(this.getMedian(win_loss_stats.stats.wins[column_name].slice(-8)), this.getMedian(win_loss_stats.stats.losses[column_name].slice(-8)), team_num)
                        }
                    } catch (error) {
                        matchup_line_dict[column_name] = "n/a";
                        // console.log("matchup line", column_name);
                    }
                } else {
                    try {
                        result_dict[column_name] = this.getAverage(prediction_stats.results.map((game) => (game[column_name])))
                    } catch (error) {
                        result_dict[column_name] = "n/a";
                        // console.log("results", column_name);
                    }
                }
            }
        }

        if (this.props.params.timeframe === 'upcoming-matches') {
            return ([prediction_dict, line_rating_dict, last_n_dict, win_dict, loss_dict, matchup_perf_dict, matchup_line_dict]);
        } else {
            return ([prediction_dict, line_rating_dict, last_n_dict, result_dict]);
        }
    }

    getAPIKey(game) {
        return (game == "cricket" || game == "kabaddi") ? process.env.REACT_APP_RIMBLE3_API_KEY : process.env.REACT_APP_RIMBLE_API_KEY;
    }

    componentDidMount() {
        // Parameter Sanitization
        const game_type_encoded = encodeURIComponent(this.props.params.game_type);
        const timeframe_encoded = encodeURIComponent(this.props.params.timeframe);
        const matchid_encoded = encodeURIComponent(this.props.params.matchid);

        fetch(
            "https://rimbleanalytics.com/predictions/" + game_type_encoded + "/" + timeframe_encoded + "/?matchid=" + matchid_encoded,
            {
                method: "GET",
                headers: {
                    "x-api-key": this.getAPIKey(this.props.params.game_type),
                }
        })
        .then((res) => res.json())
        .then((json) => {
            this.getProjections(json[0].date);
            this.getPredictions(json[0], this.props.params.team_num, this.getPlayers(json[0], this.props.params.team_num, this.props.params.player), 'player_stats');

            // Lag in updating columns for cricket, so updating early
            if (this.props.params.game_type == "cricket") {
                    this.setState({columns: new Set(["type", "runs", "strikerate", "wickets", "economy", "participation"])});
            }
            if (this.props.params.game_type == "kabaddi") {
                    this.setState({columns: new Set(["type", "points_total", "participation"])});
            }
        });
    }

    getPlayerColumns() {
        if (this.props.params.game_type == "cricket" || this.props.params.game_type == "kabaddi") {
            return ["name", "image", "table"];
        } else {
            return ["name", "username", "image", "table"];
        }
    }

    render() {

        return (
            <div>
            <Layout>
            <p>If any data is missing, please try refreshing the page...</p>
            {/* <h3>Players Stats</h3> */}

            {/* {this.state.player_stats.map(item =>
            <div className="dash-player">
                <div className="dash-player-header-container">
                    <div>
                        <a href="javascript: history.go(-1)"><img className="left-arrow" src={arrow} href="javascript: history.go(-1)"></img></a>
                    </div>
                    <div>
                        <img className="dash-player-image" src={item.image.props.element.props.src}></img>
                        {console.log(item)}
                    </div>
                    <div>
                        <h1 className="dash-player-name">{item.username}</h1>
                        <p className="dash-player-stats">Actual kills: {item.table.props.element.props.data[1].kills} | Deaths: {item.table.props.element.props.data[1].deaths} | Assists: {item.table.props.element.props.data[1].assists}</p>
                    </div>
                </div>
                <table className="dash-player-table">
                    <tr>
                        <th className={this.props.params.team_num === 0 ? "dash-player-team2-header" : "dash-player-team1-header" }>Type</th>
                        <th className={this.props.params.team_num === 0 ? "dash-player-team2-header" : "dash-player-team1-header" }>Kills</th>
                        <th className={this.props.params.team_num === 0 ? "dash-player-team2-header" : "dash-player-team1-header" }>Deaths</th>
                        <th className={this.props.params.team_num === 0 ? "dash-player-team2-header" : "dash-player-team1-header" }>Assists</th>
                    </tr>
                    <tr>
                        <td>Actual</td>
                        <td>{item.table.props.element.props.data[1].kills}</td>
                        <td>{item.table.props.element.props.data[1].deaths}</td>
                        <td>{item.table.props.element.props.data[1].assists}</td>
                        {console.log(this.props.params.team_num)}
                    </tr>
                    <tr>
                        <td>Predicted</td>
                        <td>{item.table.props.element.props.data[0].kills}</td>
                        <td>{item.table.props.element.props.data[0].deaths}</td>
                        <td>{item.table.props.element.props.data[0].assists}</td>
                    </tr>
                </table>
            </div>
                )} */}
                <Grid
                    data={this.state.player_stats}
                    columns={this.getPlayerColumns()}
                    sort={true}
                    search={true}
                    />
                <Grid
                    data={this.state.projections}
                    columns={this.state.projections_cols}
                    sort={true}
                    search={true}
                    />
            </Layout>
            </div>
        );
    }
}

export default (props) => (
    <Players
        {...props}
        params={useParams()}
    />
);
