import './App.css';
import { HashRouter, Route, Routes, BrowserRouter as Router} from 'react-router-dom';
import LandingPage from "./pages/LandingPage.js"
import About from './pages/About.js';
import Dashboard from './pages/Dashboard.js';
import Teams from './pages/Teams.js';
import Players from './pages/Players.js';
import "@fontsource/dm-sans"; 
// import ReactGA from 'react-ga';
import React, {useEffect } from 'react';
import PBDashboard from './pages/PBDashboard';
import PBMatch from './pages/PBMatch';

// const TRACKING_ID = "UA-173231277-1"; 
// ReactGA.initialize(TRACKING_ID);

function App() {

  // useEffect(() => {ReactGA.pageview(window.location.pathname + window.location.search + window.location.hash);}, []);

  return (
    <div className="App">
    <HashRouter>
      <Routes>
        <Route path="/" element={<LandingPage/>}></Route>
        <Route path="/about" element={<About/>}></Route>
        <Route exact path="/dashboard" element={<Dashboard/>}/>
        <Route exact path="/teams/:game_type/:team_1/:team_2/:timeframe/:matchid" element={<Teams/>}/>
        <Route exact path="/players/:game_type/:team_1/:team_2/:team_num/:timeframe/:matchid/:player" element={<Players/>}/>
        <Route exact path="/peel-blue-dashboard" element={<PBDashboard/>}/>
        <Route exact path="/peel-blue-match/:game_type/:matchid/:date/:match_type" element={<PBMatch/>}/>
      </Routes>
    </HashRouter>
    </div>
  );
}

export default App;
