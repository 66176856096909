import Layout from "../components/Layout.js"
import OurVision from "../components/About/our-vision.js";
import OurTeam from "../components/About/our-team.js";
import "../components/About/css/about.css"

function About() {
    return (
        <Layout>
            <div className="about">
                <h1 className="about-header">
                    We created Rimble to provide more accurate data to the eSports industry
                </h1>
            </div>
            <OurVision/>
            <OurTeam/>
        </Layout>
    )
}

export default About;