import React, {useState} from "react"
import "../SupportGames/css/supported-games.css"
import {Container, Row, Col } from "react-bootstrap";
import dota_2 from "../../../assets/images/supported_games/dota2_not_selected.png"
import dota_2_a from "../../../assets/images/supported_games/dota2_selected.png"
import league from "../../../assets/images/supported_games/league_not_selected.png"
import league_a from "../../../assets/images/supported_games/league_selected.png"
import valorant from "../../../assets/images/supported_games/valorant_not_selected.png"
import valorant_a from "../../../assets/images/supported_games/valorant_selected.png"
import rocket_league from "../../../assets/images/supported_games/rocket_league_not_selected.png"
import rocket_league_a from "../../../assets/images/supported_games/rocket_league_selected.png"
import cod from "../../../assets/images/supported_games/call_of_duty_not_selected.png"
import cod_a from "../../../assets/images/supported_games/call_of_duty_selected.png"
import csgo from "../../../assets/images/supported_games/csgo_not_selected.png"
import csgo_a from "../../../assets/images/supported_games/csgo_selected.png"
import GameCard from "../../GameCard/GameCard.js";
import GameCardData from "../../GameCard/GameCardData.js";

function SupportedGames() {

    const [active, setActive] = useState("lol")


    const activeHandler = (event) => {
        setActive(event.target.id)
    }

    return (
        <div className="supported-games">
            <Container className="supported-games-container">
                <Row className="sg-header-container">
                    <p className="sg-header">SUPPORTED GAMES</p>
                    <p className="sg-header-2">Extensive coverage</p>
                    <p className="sg-header-text">Click on the game to view the stats!</p>
                </Row>
                <Col className="sg-icon-container">
                    <div className="sg-icons">
                        <Row className="sg-icon-group">
                            <div className="game-icon-container">
                                <img id="lol" src={active === "lol" ? league_a : league} className={active === "lol" ? "game-icon-a" : "game-icon"} 
                                onClick={(e) => activeHandler(e)}></img>
                                <p className={active === "lol" ? "game-info-text-a" : "game-info-text"}>League</p>
                            </div>
                            <div className="game-icon-container">
                                <img id="dota2" src={active === "dota2" ? dota_2_a : dota_2} className={active === "dota2" ? "game-icon-a" : "game-icon"} 
                                onClick={(e) => activeHandler(e)}></img>
                                <p className={active === "dota2" ? "game-info-text-a" : "game-info-text"}>Dota 2</p>
                            </div>
                        </Row>
                        <Row className="sg-icon-group">
                            <div className="game-icon-container">
                                <img id="csgo" src={active === "csgo" ? csgo_a : csgo} className={active === "csgo" ? "game-icon-a" : "game-icon"} onClick={(e) => activeHandler(e)}></img>
                                <p className={active === "csgo" ? "game-info-text-a" : "game-info-text"}>CSGO</p>
                            </div>
                            <div className="game-icon-container">
                                <img id="cod" src={active === "cod" ? cod_a : cod} className={active === "cod" ? "game-icon-a" : "game-icon"}
                                onClick={(e) => activeHandler(e)}></img>
                                <p className={active === "cod" ? "game-info-text-a" : "game-info-text"}>Call of Duty</p>
                            </div>
                        </Row>
                        <Row className="sg-icon-group">
                            <div className="game-icon-container">
                                <img id="val" src={active === "val" ? valorant_a : valorant} className={active === "val" ? "game-icon-a" : "game-icon"} 
                                onClick={(e) => activeHandler(e)}></img>
                                <p className={active === "val" ? "game-info-text-a" : "game-info-text"}>Valorant</p>
                            </div>
                            <div className="game-icon-container">
                                <img id="rocket" src={active === "rocket" ? rocket_league_a : rocket_league} className={active === "rocket" ? "game-icon-a" : "game-icon"} 
                                onClick={(e) => activeHandler(e)}></img>
                                <p className={active === "rocket" ? "game-info-text-a" : "game-info-text"}>Rocket League</p>
                            </div>
                        </Row>
                    </div>
                    <div className="game-card-container">
                        {active === "lol" && <GameCard data={GameCardData} id="lol"/>} 
                        {active === "dota2" && <GameCard data={GameCardData} id="dota2"/>}
                        {active === "val" && <GameCard data={GameCardData} id="val"/>}
                        {active === "rocket" && <GameCard data={GameCardData} id="rocket"/>}
                        {active === "cod" && <GameCard data={GameCardData} id="cod"/>}
                        {active === "csgo" && <GameCard data={GameCardData} id="csgo"/>}
                    </div>
                </Col>
            </Container>
        </div>
    )

}

export default SupportedGames;