import mobile_error from "../../assets/images/mobile_error/mobile_error.png";
import "./css/mobile_error.css";


function MobileError() {
    return (
        <div className="mobile-error-container">
            <img className="mobile-error-image" src={mobile_error}></img>
            <h1 className="mobile-error-header">Sorry, this website isn't supported on mobile devices.</h1>
            <p className="mobile-error-text">Open rimble.io on a desktop for the prime experience.</p>
        </div>
    )
}

export default MobileError;