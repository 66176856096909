import React from "react";
import "../components/Home/OurSolutions/css/our-solutions.css";


const Card = ({data}) => {
    console.log("Re rendering!")
    if (Object.keys(data).length === 5) {
        return (
            <div className="cards">  
                <div className="card-info-container" key={data.name}>
                    <div className="card-icon-container">
                        <div className="card-icon-group">
                            <img src={data.image} className="pre_game_odds" loading="lazy" decoding="async"></img>
                            <p className="os-text-header">Pre-game odds</p>
                        </div>
                        <div className="card-icon-group">
                        <img src={data.image_2} className="pick_em" loading="lazy" decoding="async"></img>
                            <p className="os-text-header">Pick em style games</p>
                        </div>
                    </div>
                    <div className="card-icon-container">
                        <div className="card-icon-group">
                            <img src={data.image_3} className="micro_market" loading="lazy" decoding="async"></img>
                            <p className="os-text-header">In-game micromarkets</p>
                        </div>
                        <div className="card-icon-group">
                            <img src={data.image_4} className="white_label" loading="lazy" decoding="async"></img>
                            <p className="os-text-header">White label solution</p>
                        </div>
                    </div>
                </div>
            

    </div>
        )
     } if (Object.keys(data).length === 4) {
        return (
            <div className="cards">
                <div className="card-info-container" key={data.name}>
                    <div className="card-icon-container">
                        <div className="card-icon-group">
                            <img src={data.image[0]} className={data.image[2]}></img>
                            <p className="os-text-header">{data.image[1]}</p>
                        </div>
                        <div className="card-icon-group">
                            <img src={data.image_2[0]} className={data.image_2[2]}></img>
                            <p className="os-text-header">{data.image_2[1]}</p>
                        </div>
                    </div>
                    <div className="card-icon-container">
                        <div className="card-icon-group">
                            <img src={data.image_3[0]} className={data.image_3[2]}></img>
                            <p className="os-text-header">{data.image_3[1]}</p>
                            {/* <p className="os-text-description">Product 2 description. This is the product description.</p> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    } if (Object.keys(data).length === 3) {
        return (
            <div className="cards">
                    <div className="card-container-2" key={data.name}>
                    <div className="card-icon-group">
                        <img src={data.image[0]} className={data.image[2]}></img>
                        <p className="os-text-header">{data.image[1]}</p>
                    </div>
                    <div className="card-icon-group">
                        <img src={data.image_2[0]} className={data.image_2[2]}></img>
                        <p className="os-text-header">{data.image_2[1]}</p>
                    </div>
                    </div>
            </div>
        )
     }
};

export default Card;