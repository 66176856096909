import Footer from "../components/Footer/Footer.js";
import Navbar from "./NavBar/NavBar.js";
import "../App.css"
import "../index.css"

function Layout({children}) {
    return (
        <div className="container">
            <Navbar/>
            <main>
                {children}
            </main>
            <Footer/>
        </div>
    )
}

export default Layout;