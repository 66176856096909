import "../HomeHeader/css/home-header.css"
import {Button, Container, Col, Row, Stack} from 'react-bootstrap';
import rimble_computer from "../../../assets/images/rimble-computer.png";
// import MyImage from "../../MyImage";


function HomeHeader() {
    return (
        <div className="home-header">
            
            {/* <Row className="home-header-container"> */}
            {/* <div className="home-header-container"> */}
                <Col className="rb-header-text-container">
                    <h1 className="rb-home-heading">Next Generation eSports Fan Engagement</h1>
                    <p className="rb-home-text">Sophisticated machine learning models and proprietary raw data to create infrastructure for delightful  esports experiences</p>
                    <Row className="home-header-links">
                            <Button className={"rb-button"} href="contact-us"
                                onClick={(event) => {
                                    event.preventDefault();
                                    document.getElementById('contact-us').scrollIntoView(
                                        { behavior: 'smooth' }
                                    )
                                }}>
                            Get Started
                            </Button>
                            <Button className="learn-more textarea" href="how-it-works" onClick={(event) => {
                                    event.preventDefault();
                                    document.getElementById("how-it-works" ).scrollIntoView(
                                        { behavior: 'smooth' }
                                    )
                                }}>Learn More</Button>
                        </Row>
                </Col>

                <img src={rimble_computer} className="rb-home-computer"/>
            {/* </div> */}
            {/* </Row> */}
            {/* <Container className="home-header">
                <Row className="home-header-container">
                    <Col className="rb-header-text-container">
                        <p className="rb-home-heading">Next Generation eSports Fan Engagement</p>
                        <p className="rb-home-text">Sophisticated machine learning models and proprietary raw data to create infrastructure for delightful  esports experiences</p>
                        <Row className="home-header-links">
                            <Button className={"rb-button"} href="#contact-us"
                                onClick={(event) => {
                                    event.preventDefault();
                                    document.getElementById('contact-us').scrollIntoView(
                                        { behavior: 'smooth' }
                                    )
                                }}>
                            Get Started
                            </Button>
                            <Button className="learn-more" href="how-it-works" onClick={(event) => {
                                    event.preventDefault();
                                    document.getElementById("how-it-works" ).scrollIntoView(
                                        { behavior: 'smooth' }
                                    )
                                }}>Learn More</Button>
                        </Row>
                    </Col>
                    <div className="rb-computer-container">
                        <img src={rimble_computer} className="rb-home-computer"/>
                    </div>
                </Row> */}
            {/* </Container> */}
        </div>
    );
}

export default HomeHeader;